(function() {
	'use strict';

	var $ = avm.require('cash'),
    modals = document.querySelectorAll('.modal');

	modals.forEach(function(modal) {
		var $modalContent = $(modal).find('.modal-content'),
			$videoElement = $modalContent.find('video')[0];

		modal.addEventListener('shown.bs.modal', function() {
			var $iframe = $modalContent.find('iframe');

			if ($iframe.length !== 0) {

				$iframe[0].contentWindow.postMessage(
					'{ "event":"command", "func":"playVideo", "args":"" }', '*'
				);
			} 
		});

		modal.addEventListener('hide.bs.modal', function() {
			var $iframe = $modalContent.find('iframe');

			if ($iframe.length !== 0) {
				$iframe[0].contentWindow.postMessage(
					'{ "event":"command", "func":"pauseVideo", "args":"" }', '*'
				);
			} else if ($videoElement) {
				$videoElement.load();
			}
		});
	});


	// VIDEO AUTOPLAY FIX GLOWEB-9377
	// Add click event listener to `.icon-play-l` to trigger autoplay
	var playIcons = document.querySelectorAll('.icon-play-l');
	playIcons.forEach(function(playIcon) {
		playIcon.addEventListener('click', function() {
			// Locate the modal video iframe
			const video = document.querySelector('.modal-video');
			
			if (video) {
				// Append autoplay=1 to the video src only if it's not already added
				var videoSrc = video.getAttribute('src');
				if (!videoSrc.includes('autoplay=1')) {
					video.setAttribute('src', videoSrc + '&autoplay=1');
				}
			}
		});
	});

})();
